
import objectToFormData from '~/utilities/object-to-formdata'

export const DUPLICATE_TIMEOUT_MESSAGE = 'DUPLICATE_TIMEOUT'

export default ({ $axios, store, app }) => ({
  getStripeDashboardLink: () =>
    $axios.$get('/vendor/stripe_dashboard')
      .then(({ stripe_link: link }) => link),

  submitStripeConnectCode: (params) =>
    $axios.$post('/vendor/vendors/stripe_callback', params),

  getPersonalInformation: data =>
    $axios.$get('/vendor/vendors.json'),

  getTermsOfService: () =>
    $axios.$get('/vendor/vendor_term_of_service.json')
      .then(res => res.term_of_service),

  getPaymentInformation: () =>
    $axios.$get('/vendor/payment_information.json'),

  updatePaymentInformation: data =>
    $axios.$put('/vendor/payment_information.json', data),

  updatePlaidAccessToken: data =>
    $axios.$put('/vendor/plaids/access_token.json', data),

  createPaymentInformation: data =>
    $axios.$post('/vendor/payment_information.json', data),

  deletePaymentInformation: () =>
    $axios.$delete('/vendor/payment_information.json'),

  changePassword: data =>
    $axios.$put('/vendor/vendor_auth.json', data),

  updatePersonalInformation: vendor =>
    $axios.$put('/vendor/vendors.json', { vendor }),

  updateCompanyInformation: data =>
    $axios.$put('/vendor/vendor_companies.json', objectToFormData({
      vendor_company: data
    })),

  getOrders: params =>
    $axios.$get('/vendor/orders.json', { params }),

  getCanceledOrders: params =>
    $axios.$get('/vendor/deleted/orders.json', { params }),

  getOrder: ({ id, ...params }, config) =>
    $axios.$get(`/vendor/orders/${id}.json`, { ...config, params }),

  getCanceledOrder: ({ id, ...params }) =>
    $axios.$get(`/vendor/deleted/orders/${id}.json`, { params }),

  getSampleRequests: params =>
    $axios.$get('/vendor/sample_requests.json', { params }),

  getSampleRequest: ({ id, ...params }) =>
    $axios.$get(`/vendor/sample_requests/${id}.json`, { params }),

  cancelSampleRequest: ({ id, ...sample_request }) =>
    $axios.$put(`/vendor/sample_requests/${id}.json`, { sample_request }),

  getStores: params =>
    $axios.$get('/vendor/stores.json', { params }),

  getRelevantStores: params =>
    $axios.$get('/vendor/relevant_stores.json', { params }),

  getStore: ({ id, ...params }) =>
    $axios.$get(`/vendor/stores/${id}.json`, { params }),

  createProduct: (payload) =>
    $axios.$post('/vendor/products.json', objectToFormData(payload)),

  getProducts: params =>
    $axios.$get('/vendor/products.json', { params }),

  getProduct: ({ id, ...params }) =>
    $axios.$get(`/vendor/products/${id}.json`, { params }),

  getProductVariants: params =>
    $axios.$get('/vendor/product_variants.json', { params }),

  updateProduct: ({ id, ...product }) =>
    $axios.$put(`/vendor/products/${id}.json`, objectToFormData({ product })),

  createSku: (id, payload) =>
    $axios.$post(`/vendor/products/${id}/product_variants.json`, objectToFormData(payload)),

  updateSku: (id, payload) =>
    $axios.$put(`/vendor/product_variants/${id}.json`, objectToFormData(payload)),

  postProp65: (payload) =>
    $axios.$post('/vendor/chemical_prop_forms.json', { chemical_prop_form: payload }),

  productChangeRequest: (params) =>
    $axios.$post('/vendor/product_change_requests.json', { product_change_request: params }),

  updateProductChangeRequest: (params, id) =>
    $axios.$put(`/vendor/product_change_requests/${id}.json`, { id, product_change_request: params }),

  deleteProductChangeRequest: (id) =>
    $axios.$delete(`/vendor/product_change_requests/${id}.json`),

  deactivateProduct: ({ id, ...data }) =>
    $axios.$put(`/vendor/products/${id}/deactivate.json`, data),

  duplicateProduct: (id, data) =>
    $axios.$put(`/vendor/products/${id}/duplicate.json`, data, { timeoutErrorMessage: DUPLICATE_TIMEOUT_MESSAGE }),

  exportProducts: () =>
    $axios.$get('/vendor/export/product_variants.json')
      .then(({ results }) => results),

  getSku: (id) =>
    $axios.$get(`/vendor/product_variants/${id}.json`).then((res) => res.product_variant),

  deactivateSku: (id) =>
    $axios.$put(`/vendor/product_variants/${id}/deactivate.json`),

  duplicateSku: (id, data) =>
    $axios.$put(`/vendor/product_variants/${id}/duplicate.json`, data),

  getBrands: params =>
    $axios.$get('/vendor/brands.json', { params }),

  getBrand: ({ id, ...params }) =>
    $axios.$get(`/vendor/brands/${id}.json`, { params }),

  deactivateBrand: ({ id, ...params }) =>
    $axios.$put(`/vendor/brands/${id}/deactivate.json`, params),

  createBrand: form =>
    $axios.$post('/vendor/brands.json', form),

  updateBrand: ({ id, ...data }) =>
    $axios.$put(`/vendor/brands/${id}.json`, objectToFormData({
      brand: data
    })),

  getRegions: params =>
    $axios.$get('/vendor/regions.json', { params })
      .then(res => res.results),

  getRelevantRegions: params =>
    $axios.$get('/vendor/relevant_regions.json', { params })
      .then(res => res.results),

  getInventories: params =>
    $axios.$get('/vendor/inventories.json', { params }),

  getInventoryRequests: params =>
    $axios.$get('/vendor/inventory_requests.json', { params }),

  getInventoryRequest: (id) =>
    $axios.$put(`vendor/inventory_requests/${id}.json`),

  createInventoryRequest: data =>
    $axios.$post('/vendor/inventory_requests.json', { inventory_request: data }),

  updateInventoryRequest: ({ id, ...params }) =>
    $axios.$put(`vendor/inventory_requests/${id}.json`, params),

  getInventoryStatuses: params =>
    $axios.$get('/vendor/inventory_status/product_variants.json', { params }),

  getInboundInventories: params =>
    $axios.$get('/vendor/inbound_inventories.json', { params }),

  getInboundInventory: ({ id, ...params }) =>
    $axios.$get(`/vendor/inbound_inventories/${id}.json`, { params }),

  createInboundInventory: data =>
    $axios.$post('/vendor/inbound_inventories.json', objectToFormData({
      inbound_inventory: data
    })),

  updateInboundInventory: ({ id, ...data }) =>
    $axios.$put(`/vendor/inbound_inventories/${id}.json`, objectToFormData({
      inbound_inventory: data
    })),

  exportInboundInventoryReplenishmentPDF: id =>
    $axios.$get(`/vendor/export/inbound_inventories/${id}/replenishment_pdf`, { responseType: 'blob' }),

  exportInboundPlacardLabelPDF: id =>
    $axios.$get(`/vendor/export/inbound_inventories/${id}/placard_label_pdf`, { responseType: 'blob' }),

  getInventoryWithdrawalRequests: params =>
    $axios.$get('/vendor/withdraw_inventories.json', { params }),

  getInventoryWithdrawalRequest: ({ id, ...params }) =>
    $axios.$get(`/vendor/withdraw_inventories/${id}.json`, { params }),

  createInventoryWithdrawalRequest: data =>
    $axios.$post('/vendor/withdraw_inventories.json', objectToFormData({
      withdraw_inventory: data
    })),

  updateInventoryWithdrawalRequest: ({ id, ...data }) =>
    $axios.$put(`/vendor/withdraw_inventories/${id}.json`, objectToFormData({
      withdraw_inventory: data
    })),

  exportInventories: params =>
    $axios.$get('vendor/export/inventories/csv.json', { params })
      .then(({ results }) => results),

  exportSnapshotInventories: params =>
    $axios.$get('vendor/export/snapshot_inventories/csv.json', { params })
      .then(({ results }) => results),

  getCompanies: params =>
    $axios.$get('/vendor/vendor_companies.json', { params }),

  getPayouts: params =>
    $axios.$get('/vendor/vendor_order_payments.json', { params }),

  exportPayouts: params =>
    $axios.$get('/vendor/export/vendor_order_payments.json', { params })
      .then(({ results }) => results),

  getStatements: params =>
    $axios.$get('/vendor/vendor_statements.json', { params }),

  getBrandCommissions: params =>
    $axios.$get('/vendor/commissions/brands.json', { params }),

  getPlaidLinkToken: () =>
    $axios.$get('/vendor/plaids/link_token.json').then(res => res.link_token),

  getTotalRevenue: (params) =>
    $axios.$get(`/vendor/brand_analytics/${params.type}s/total_revenue.json`, { params })
      .then(({ data }) => data),

  getTotalSale: (params) =>
    $axios.$get(`/vendor/brand_analytics/${params.type}s/total_sale.json`, { params })
      .then(({ data }) => data),

  getNumStoreOrdered: (params) =>
    $axios.$get(`/vendor/brand_analytics/${params.type}s/num_store_ordered.json`, { params }),

  getTotalOrderNumber: (params) =>
    $axios.$get(`/vendor/brand_analytics/${params.type}s/total_order_number.json`, { params })
      .then(({ data }) => data),

  createGrowthDeck: data =>
    $axios.$post('/vendor/vendor_growth_decks.json', objectToFormData({
      vendor_growth_deck: data
    })),

  updateGrowthDeck: ({ id, ...data }) =>
    $axios.$post(`/vendor/inbound_inventories/${id}.json`, objectToFormData({
      vendor_growth_deck: data
    })),

  exportOrderDetails: (params) =>
    $axios.$get('/vendor/export/line_items/csv.json', { params })
      .then(({ results }) => results),

  // orderDetailsBulkCsv: (params) =>
  //   $axios.$get('/vendor/export/line_items/bulk_csv', { params })
  //     .then(({ results }) => results),

  exportOrderSummary: (params) =>
    $axios.$get('/vendor/export/orders.json', { params })
      .then(({ results }) => results),

  getGrowthAccounting: (params) =>
    $axios.$get('/vendor/brand_analytics/growths/growth_accounting.json', { params })
      .then(({ data }) => data),

  exportTotalSale: (params) =>
    $axios.$get(`/vendor/brand_analytics/${params.type}s/export_total_sale.json`, { params })
      .then(({ data }) => data),

  exportTotalRevenue: (params) =>
    $axios.$get(`/vendor/brand_analytics/${params.type}s/export_total_revenue.json`, { params })
      .then(({ data }) => data),

  exportVariantsInventories: (params) =>
    $axios.$get('/vendor/brand_analytics/inventories/export_variants_inventories.json', { params })
      .then(({ data }) => data),

  exportLowQuantityInventories: (params) =>
    $axios.$get('/vendor/brand_analytics/inventories/export_low_quantity_inventories', { params })
      .then(({ data }) => data),

  exportExpiredInventories: (params) =>
    $axios.$get('/vendor/brand_analytics/inventories/export_expired_inventories.json', { params })
      .then(({ data }) => data),

  async getInventoryStatus (params) {
    const [variantsInventories, lowQuantityInventories, expiredInventories] = await Promise.all([
      this.exportVariantsInventories(params),
      this.exportLowQuantityInventories(params),
      this.exportExpiredInventories(params)
    ])

    if (
      !variantsInventories.length &&
      !lowQuantityInventories.length &&
      !expiredInventories.length
    ) {
      return {}
    }

    return {
      variantsInventories,
      lowQuantityInventories,
      expiredInventories
    }
  },

  getStoreLocationGmv: (params) =>
    $axios.$get('/vendor/brand_analytics/growths/store_location_gmv.json', { params })
      .then(({ data }) => data),

  getStatementDetail: id =>
    $axios.$get(`/vendor/vendor_statements/${id}.json`),

  exportStatementPDF: id =>
    $axios.$get(`/vendor/export/vendor_statements/${id}/pdf`, { responseType: 'blob' }),

  exportStatementXlsx: id =>
    $axios.$get(`/vendor/export/vendor_statements/${id}/xlsx`, { responseType: 'blob' }),

  exportStatementExcel: id =>
    $axios.$get(`/vendor/vendor_statements/${id}.json`, { responseType: 'blob' }),

  fulfillSampleRequest: ({ id, ...params }) =>
    $axios.$put(`/vendor/sample_requests/${id}.json`, params),

  createShippoShipment: (params) =>
    $axios.$post('/vendor/delivery/shippo_shipments.json', params),

  createShippoTransaction: (params) =>
    $axios.$post('/vendor/delivery/sample_request/shippo_labels.json', params),

  createPodDirectConfirmation: (params) =>
    $axios.$post('/vendor/sub_invoices.json', params),

  updatePodDirectConfirmation: ({ id, ...params }) =>
    $axios.$put(`/vendor/delivery/sub_invoices/${id}.json`, params),

  updatePodDirectSubInvoice: ({ id, ...params }) =>
    $axios.$put(`/vendor/sub_invoices/${id}.json`, params),

  updatePodDirectLineItem: ({ id, ...params }) =>
    $axios.$put(`/vendor/line_items/${id}.json`, params),

  updatePrintSubInvoice: (id) =>
    $axios.$put(`/vendor/sub_invoices/${id}/print.json`),

  deletePodDirectSubInvoice: (id) =>
    $axios.$delete(`/vendor/sub_invoices/${id}.json`),

  updateSelfDeliveryProof: ({ id, ...params }) =>
    $axios.$put(`/vendor/delivery/sub_invoices/${id}.json`, objectToFormData(params)),

  inviteColleages: (params) =>
    $axios.$post('/vendor/vendor_auth/invitation.json', params),

  acceptInvitation: (params) =>
    $axios.$put('/vendor/vendor_auth/invitation.json', params),

  getPromotions: (params) =>
    $axios.$get('/vendor/promotions.json', { params }),

  getPromotion: id =>
    $axios.$get(`/vendor/promotions/${id}.json`),

  getSubmittedPromotion: id =>
    $axios.$get(`/vendor/promotion_submissions/${id}.json`),

  getStoreLists: params =>
    $axios.$get('/vendor/store_lists.json', { params }),

  exportStoreLists: params =>
      $axios.$get('/vendor/export/store_lists/csv.json', { params, responseType: 'blob' }),

  updateStore: params =>
    $axios.$put('/vendor/store_list_items.json', params),

  updateMultipleStores: params =>
    $axios.$put('/vendor/multiple/store_list_items.json', params),

  getBuyerCompanies: params =>
    $axios.$get('/vendor/buyer_companies.json', { params }),

  getKanmonToken: (params, config = {}) =>
    $axios.$post('/vendor/fintech/kanmon/connect_tokens.json', params, config)
      .then(res => res.token),

  createKanmonBusinessAndOwner: (params, config = {}) =>
    $axios.$put('/vendor/fintech/kanmon/business_owners.json', params, config),

  referStore: params =>
    $axios.$post('/vendor/referral_store_forms.json', params),

  getDATUploadSignedUrl: params =>
    $axios.$post('/vendor/storage/attachment.json', params),

  getDATDocsSignedUrl: params =>
    $axios.$get('/vendor/storage/attachment.json', { params }),

  getClaims: params =>
    $axios.$get('/vendor/vendor_claims.json', { params }),

  getClaim: ({ id, ...params }) =>
    $axios.$get(`/vendor/vendor_claims/${id}.json`, { params }),

  createClaim: data =>
    $axios.$post('/vendor/vendor_claims.json', objectToFormData({
      vendor_claim: data
    })),

  updateClaim: ({ id, ...data }) =>
    $axios.$put(`/vendor/vendor_claims/${id}.json`, objectToFormData({
      vendor_claim: data
    })),

  createVendorPromotion: data =>
    $axios.$post('/vendor/promotions.json', { promotion_submission: data }),

  updateVendorPromotion: ({ id, ...data }) =>
     $axios.$put(`/vendor/promotions/${id}.json`, { promotion_submission: data }),

  getNotifications: (params) =>
    $axios.$get('/vendor/in_app_notifications.json', { params }),

  validateShippoAddress: data =>
    $axios.$post('/vendor/delivery/shippo_addresses.json', { address: data }),

  getInventoryTransfers: params =>
    $axios.$get('vendor/inventory_transfers.json', { params }),

  getInventoryTransfer: ({ id, ...params }) =>
    $axios.$get(`vendor/inventory_transfers/${id}.json`, { params }),

  getAnalytics: params =>
    $axios.$get('/vendor/analytics/statistical_data.json', { params }),

  exportAnalytics: params =>
    $axios.$get('/vendor/export/statistical_data.json', { params }),

  createBoxOfInnovation: data =>
    $axios.$post('/vendor/box_of_innovations.json', objectToFormData({ box_of_innovation: data }))
})
