import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ea11446 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _60c7e037 = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _059d3788 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _ecd9992e = () => interopDefault(import('../pages/bloomberg-2024.vue' /* webpackChunkName: "pages/bloomberg-2024" */))
const _ac75505c = () => interopDefault(import('../pages/box-of-innovation.vue' /* webpackChunkName: "pages/box-of-innovation" */))
const _5f8daf40 = () => interopDefault(import('../pages/brand.vue' /* webpackChunkName: "pages/brand" */))
const _607fabb4 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _3f9ef1b3 = () => interopDefault(import('../pages/brokers.vue' /* webpackChunkName: "pages/brokers" */))
const _48b69088 = () => interopDefault(import('../pages/brokers/settings/general.vue' /* webpackChunkName: "pages/brokers/settings/general" */))
const _165592f0 = () => interopDefault(import('../pages/brokers/settings/notifications.vue' /* webpackChunkName: "pages/brokers/settings/notifications" */))
const _4b891f37 = () => interopDefault(import('../pages/buyers.vue' /* webpackChunkName: "pages/buyers" */))
const _1339207a = () => interopDefault(import('../pages/buyers/index.vue' /* webpackChunkName: "pages/buyers/index" */))
const _8bbe059e = () => interopDefault(import('../pages/buyers/analytics/index.vue' /* webpackChunkName: "pages/buyers/analytics/index" */))
const _2636bfb0 = () => interopDefault(import('../pages/buyers/orders/index.vue' /* webpackChunkName: "pages/buyers/orders/index" */))
const _ccaf1410 = () => interopDefault(import('../pages/buyers/payments/index.vue' /* webpackChunkName: "pages/buyers/payments/index" */))
const _61814418 = () => interopDefault(import('../pages/buyers/samples/index.vue' /* webpackChunkName: "pages/buyers/samples/index" */))
const _5a9de76e = () => interopDefault(import('../pages/buyers/settings/index.vue' /* webpackChunkName: "pages/buyers/settings/index" */))
const _cf7b0922 = () => interopDefault(import('../pages/buyers/payments/credit-memos.vue' /* webpackChunkName: "pages/buyers/payments/credit-memos" */))
const _15dda6b1 = () => interopDefault(import('../pages/buyers/payments/statements/index.vue' /* webpackChunkName: "pages/buyers/payments/statements/index" */))
const _4e049b84 = () => interopDefault(import('../pages/buyers/settings/general.vue' /* webpackChunkName: "pages/buyers/settings/general" */))
const _37cb64f8 = () => interopDefault(import('../pages/buyers/settings/invite-colleagues.vue' /* webpackChunkName: "pages/buyers/settings/invite-colleagues" */))
const _58961484 = () => interopDefault(import('../pages/buyers/settings/notifications.vue' /* webpackChunkName: "pages/buyers/settings/notifications" */))
const _845bf4bc = () => interopDefault(import('../pages/buyers/settings/payment.vue' /* webpackChunkName: "pages/buyers/settings/payment" */))
const _63c0cc7a = () => interopDefault(import('../pages/buyers/orders/list/_type.vue' /* webpackChunkName: "pages/buyers/orders/list/_type" */))
const _1a468f2d = () => interopDefault(import('../pages/buyers/orders/pre-order/_id.vue' /* webpackChunkName: "pages/buyers/orders/pre-order/_id" */))
const _317478b6 = () => interopDefault(import('../pages/buyers/samples/list/_type.vue' /* webpackChunkName: "pages/buyers/samples/list/_type" */))
const _66d0ee18 = () => interopDefault(import('../pages/buyers/orders/_id.vue' /* webpackChunkName: "pages/buyers/orders/_id" */))
const _c9976548 = () => interopDefault(import('../pages/buyers/samples/_id.vue' /* webpackChunkName: "pages/buyers/samples/_id" */))
const _9f2ebbb8 = () => interopDefault(import('../pages/capital/index.vue' /* webpackChunkName: "pages/capital/index" */))
const _2dad3da4 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _3eba38f7 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _d4905ec6 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _7c81a975 = () => interopDefault(import('../pages/claim.vue' /* webpackChunkName: "pages/claim" */))
const _175f264e = () => interopDefault(import('../pages/claims.vue' /* webpackChunkName: "pages/claims" */))
const _6155ad90 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _d01e1830 = () => interopDefault(import('../pages/growth-deck.vue' /* webpackChunkName: "pages/growth-deck" */))
const _60a329c6 = () => interopDefault(import('../pages/head-buyer.vue' /* webpackChunkName: "pages/head-buyer" */))
const _de2485d2 = () => interopDefault(import('../pages/head-buyer/analytics/index.vue' /* webpackChunkName: "pages/head-buyer/analytics/index" */))
const _2d86798a = () => interopDefault(import('../pages/head-buyer/orders/index.vue' /* webpackChunkName: "pages/head-buyer/orders/index" */))
const _0bf12152 = () => interopDefault(import('../pages/head-buyer/payments/index.vue' /* webpackChunkName: "pages/head-buyer/payments/index" */))
const _9c32414c = () => interopDefault(import('../pages/head-buyer/samples/index.vue' /* webpackChunkName: "pages/head-buyer/samples/index" */))
const _6632da70 = () => interopDefault(import('../pages/head-buyer/settings/index.vue' /* webpackChunkName: "pages/head-buyer/settings/index" */))
const _a5eae5ae = () => interopDefault(import('../pages/head-buyer/analytics/avg-drop-size.vue' /* webpackChunkName: "pages/head-buyer/analytics/avg-drop-size" */))
const _24a7ebf4 = () => interopDefault(import('../pages/head-buyer/analytics/avg-order-size.vue' /* webpackChunkName: "pages/head-buyer/analytics/avg-order-size" */))
const _298eeb77 = () => interopDefault(import('../pages/head-buyer/analytics/best-selling-brands.vue' /* webpackChunkName: "pages/head-buyer/analytics/best-selling-brands" */))
const _2517c6a2 = () => interopDefault(import('../pages/head-buyer/analytics/fill-rate.vue' /* webpackChunkName: "pages/head-buyer/analytics/fill-rate" */))
const _010e7c62 = () => interopDefault(import('../pages/head-buyer/analytics/number-of-orders.vue' /* webpackChunkName: "pages/head-buyer/analytics/number-of-orders" */))
const _1a67b330 = () => interopDefault(import('../pages/head-buyer/analytics/sale-velocity.vue' /* webpackChunkName: "pages/head-buyer/analytics/sale-velocity" */))
const _71531196 = () => interopDefault(import('../pages/head-buyer/analytics/stock-report.vue' /* webpackChunkName: "pages/head-buyer/analytics/stock-report" */))
const _65d4860e = () => interopDefault(import('../pages/head-buyer/orders/create-multiple.vue' /* webpackChunkName: "pages/head-buyer/orders/create-multiple" */))
const _731d36d5 = () => interopDefault(import('../pages/head-buyer/payments/credit-memos.vue' /* webpackChunkName: "pages/head-buyer/payments/credit-memos" */))
const _44803f17 = () => interopDefault(import('../pages/head-buyer/payments/statements/index.vue' /* webpackChunkName: "pages/head-buyer/payments/statements/index" */))
const _50cfd85e = () => interopDefault(import('../pages/head-buyer/settings/general.vue' /* webpackChunkName: "pages/head-buyer/settings/general" */))
const _1913cf07 = () => interopDefault(import('../pages/head-buyer/orders/pre-order/_id.vue' /* webpackChunkName: "pages/head-buyer/orders/pre-order/_id" */))
const _a33831e0 = () => interopDefault(import('../pages/head-buyer/samples/list/_type.vue' /* webpackChunkName: "pages/head-buyer/samples/list/_type" */))
const _3e995672 = () => interopDefault(import('../pages/head-buyer/orders/_id.vue' /* webpackChunkName: "pages/head-buyer/orders/_id" */))
const _870e1f7c = () => interopDefault(import('../pages/head-buyer/samples/_id.vue' /* webpackChunkName: "pages/head-buyer/samples/_id" */))
const _0255965a = () => interopDefault(import('../pages/help-center.vue' /* webpackChunkName: "pages/help-center" */))
const _7beb1d46 = () => interopDefault(import('../pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _2b0293ad = () => interopDefault(import('../pages/help-center/search.vue' /* webpackChunkName: "pages/help-center/search" */))
const _7f585fd6 = () => interopDefault(import('../pages/help-center/_slug.vue' /* webpackChunkName: "pages/help-center/_slug" */))
const _28009222 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _604fbd1b = () => interopDefault(import('../pages/lp.vue' /* webpackChunkName: "pages/lp" */))
const _4ae2c35e = () => interopDefault(import('../pages/lp/index.vue' /* webpackChunkName: "pages/lp/index" */))
const _69b6c4e8 = () => interopDefault(import('../pages/lp/inventory.vue' /* webpackChunkName: "pages/lp/inventory" */))
const _1305fd6b = () => interopDefault(import('../pages/lp/inventory/index.vue' /* webpackChunkName: "pages/lp/inventory/index" */))
const _0dbcfdfd = () => interopDefault(import('../pages/lp/inventory/all/index.vue' /* webpackChunkName: "pages/lp/inventory/all/index" */))
const _524bd797 = () => interopDefault(import('../pages/lp/inventory/dispose-donate/index.vue' /* webpackChunkName: "pages/lp/inventory/dispose-donate/index" */))
const _24ca8955 = () => interopDefault(import('../pages/lp/inventory/inbound/index.vue' /* webpackChunkName: "pages/lp/inventory/inbound/index" */))
const _7ef47678 = () => interopDefault(import('../pages/lp/inventory/withdrawal-requests/index.vue' /* webpackChunkName: "pages/lp/inventory/withdrawal-requests/index" */))
const _419b9eea = () => interopDefault(import('../pages/lp/inventory/all/new.vue' /* webpackChunkName: "pages/lp/inventory/all/new" */))
const _690748b6 = () => interopDefault(import('../pages/lp/inventory/all/_id.vue' /* webpackChunkName: "pages/lp/inventory/all/_id" */))
const _5148d3bf = () => interopDefault(import('../pages/lp/inventory/dispose-donate/_id.vue' /* webpackChunkName: "pages/lp/inventory/dispose-donate/_id" */))
const _0aa4dcfd = () => interopDefault(import('../pages/lp/inventory/inbound/_id.vue' /* webpackChunkName: "pages/lp/inventory/inbound/_id" */))
const _80c49240 = () => interopDefault(import('../pages/lp/inventory/withdrawal-requests/_id.vue' /* webpackChunkName: "pages/lp/inventory/withdrawal-requests/_id" */))
const _9d425768 = () => interopDefault(import('../pages/lp/orders/index.vue' /* webpackChunkName: "pages/lp/orders/index" */))
const _63219ac7 = () => interopDefault(import('../pages/lp/pod-freight.vue' /* webpackChunkName: "pages/lp/pod-freight" */))
const _78cab00a = () => interopDefault(import('../pages/lp/pod-freight/index.vue' /* webpackChunkName: "pages/lp/pod-freight/index" */))
const _02792cf2 = () => interopDefault(import('../pages/lp/pod-freight/_id.vue' /* webpackChunkName: "pages/lp/pod-freight/_id" */))
const _87d975ec = () => interopDefault(import('../pages/lp/settings/index.vue' /* webpackChunkName: "pages/lp/settings/index" */))
const _27990220 = () => interopDefault(import('../pages/lp/settings/general.vue' /* webpackChunkName: "pages/lp/settings/general" */))
const _f2250c98 = () => interopDefault(import('../pages/lp/orders/_id.vue' /* webpackChunkName: "pages/lp/orders/_id" */))
const _78653fb6 = () => interopDefault(import('../pages/order-guide.vue' /* webpackChunkName: "pages/order-guide" */))
const _053f7645 = () => interopDefault(import('../pages/ordered-variants.vue' /* webpackChunkName: "pages/ordered-variants" */))
const _b1f24d40 = () => interopDefault(import('../pages/pod-3pl.vue' /* webpackChunkName: "pages/pod-3pl" */))
const _e25bb3a8 = () => interopDefault(import('../pages/pod-cost.vue' /* webpackChunkName: "pages/pod-cost" */))
const _1cbcde98 = () => interopDefault(import('../pages/pod-freight.vue' /* webpackChunkName: "pages/pod-freight" */))
const _65c27bba = () => interopDefault(import('../pages/pod-insights.vue' /* webpackChunkName: "pages/pod-insights" */))
const _28f36098 = () => interopDefault(import('../pages/pod-pax.vue' /* webpackChunkName: "pages/pod-pax" */))
const _5ee23ec9 = () => interopDefault(import('../pages/podcasts/index.vue' /* webpackChunkName: "pages/podcasts/index" */))
const _584049fc = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _3f72a302 = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _7455fa81 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7c5ccf30 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _192323fe = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _2aa1a2c7 = () => interopDefault(import('../pages/promotions.vue' /* webpackChunkName: "pages/promotions" */))
const _8ae40918 = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _490b3e7d = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _517fdff0 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _266022bc = () => interopDefault(import('../pages/retailers.vue' /* webpackChunkName: "pages/retailers" */))
const _0de31d82 = () => interopDefault(import('../pages/setup-password.vue' /* webpackChunkName: "pages/setup-password" */))
const _148a4baa = () => interopDefault(import('../pages/state-of-grocery.vue' /* webpackChunkName: "pages/state-of-grocery" */))
const _4a3bcc98 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _46c5b4c8 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _6cf3f1c2 = () => interopDefault(import('../pages/terms-of-service/index.vue' /* webpackChunkName: "pages/terms-of-service/index" */))
const _df6c7762 = () => interopDefault(import('../pages/terms-of-service/brands.vue' /* webpackChunkName: "pages/terms-of-service/brands" */))
const _1bdfcca8 = () => interopDefault(import('../pages/terms-of-service/logistics-partners.vue' /* webpackChunkName: "pages/terms-of-service/logistics-partners" */))
const _f349de60 = () => interopDefault(import('../pages/terms-of-service/retailers.vue' /* webpackChunkName: "pages/terms-of-service/retailers" */))
const _79792aee = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _1f53d1a4 = () => interopDefault(import('../pages/vendors.vue' /* webpackChunkName: "pages/vendors" */))
const _02bd8d27 = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _6a4a414a = () => interopDefault(import('../pages/vendors/analytics.vue' /* webpackChunkName: "pages/vendors/analytics" */))
const _2e9b359e = () => interopDefault(import('../pages/vendors/analytics/index.vue' /* webpackChunkName: "pages/vendors/analytics/index" */))
const _7674787c = () => interopDefault(import('../pages/vendors/analytics/customer.vue' /* webpackChunkName: "pages/vendors/analytics/customer" */))
const _33261e76 = () => interopDefault(import('../pages/vendors/analytics/customer/index.vue' /* webpackChunkName: "pages/vendors/analytics/customer/index" */))
const _d3116442 = () => interopDefault(import('../pages/vendors/analytics/customer/churn.vue' /* webpackChunkName: "pages/vendors/analytics/customer/churn" */))
const _6bbc9912 = () => interopDefault(import('../pages/vendors/analytics/customer/cohort.vue' /* webpackChunkName: "pages/vendors/analytics/customer/cohort" */))
const _09dfd954 = () => interopDefault(import('../pages/vendors/analytics/customer/first-time-and-returning.vue' /* webpackChunkName: "pages/vendors/analytics/customer/first-time-and-returning" */))
const _537ee54e = () => interopDefault(import('../pages/vendors/analytics/customer/growth-accounting.vue' /* webpackChunkName: "pages/vendors/analytics/customer/growth-accounting" */))
const _14384e31 = () => interopDefault(import('../pages/vendors/analytics/customer/one-time.vue' /* webpackChunkName: "pages/vendors/analytics/customer/one-time" */))
const _1cd07499 = () => interopDefault(import('../pages/vendors/analytics/customer/over-time.vue' /* webpackChunkName: "pages/vendors/analytics/customer/over-time" */))
const _2e33205c = () => interopDefault(import('../pages/vendors/analytics/customer/sales-by-region.vue' /* webpackChunkName: "pages/vendors/analytics/customer/sales-by-region" */))
const _0927d1b0 = () => interopDefault(import('../pages/vendors/analytics/inventory.vue' /* webpackChunkName: "pages/vendors/analytics/inventory" */))
const _7cf6c0aa = () => interopDefault(import('../pages/vendors/analytics/inventory/index.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/index" */))
const _109faf26 = () => interopDefault(import('../pages/vendors/analytics/inventory/average-sold-per-week.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/average-sold-per-week" */))
const _f4db86dc = () => interopDefault(import('../pages/vendors/analytics/inventory/current.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/current" */))
const _6cab028f = () => interopDefault(import('../pages/vendors/analytics/inventory/days-of-remaining.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/days-of-remaining" */))
const _5bf174cc = () => interopDefault(import('../pages/vendors/analytics/inventory/lost-sales.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/lost-sales" */))
const _5db45f32 = () => interopDefault(import('../pages/vendors/analytics/inventory/month-end.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/month-end" */))
const _477fe9ca = () => interopDefault(import('../pages/vendors/analytics/inventory/percent-of-sold.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/percent-of-sold" */))
const _2c56aeae = () => interopDefault(import('../pages/vendors/analytics/inventory/replenishment-lead-time.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/replenishment-lead-time" */))
const _6a429b6a = () => interopDefault(import('../pages/vendors/analytics/lead-time.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time" */))
const _dc792b26 = () => interopDefault(import('../pages/vendors/analytics/lead-time/index.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/index" */))
const _34238f7b = () => interopDefault(import('../pages/vendors/analytics/lead-time/pd-order-fulfillment.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/pd-order-fulfillment" */))
const _3a3f491f = () => interopDefault(import('../pages/vendors/analytics/lead-time/replenishment.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/replenishment" */))
const _fe100222 = () => interopDefault(import('../pages/vendors/analytics/promotion.vue' /* webpackChunkName: "pages/vendors/analytics/promotion" */))
const _dd81539c = () => interopDefault(import('../pages/vendors/analytics/promotion/index.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/index" */))
const _8bbb09c2 = () => interopDefault(import('../pages/vendors/analytics/promotion/leading-to-repurchase.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/leading-to-repurchase" */))
const _20f4a8fe = () => interopDefault(import('../pages/vendors/analytics/promotion/usage.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/usage" */))
const _5d9dadab = () => interopDefault(import('../pages/vendors/analytics/sale.vue' /* webpackChunkName: "pages/vendors/analytics/sale" */))
const _1903f024 = () => interopDefault(import('../pages/vendors/analytics/sale/index.vue' /* webpackChunkName: "pages/vendors/analytics/sale/index" */))
const _1583ab53 = () => interopDefault(import('../pages/vendors/analytics/sale/avg-order-value.vue' /* webpackChunkName: "pages/vendors/analytics/sale/avg-order-value" */))
const _898d0c84 = () => interopDefault(import('../pages/vendors/analytics/sale/fulfilled-gmv.vue' /* webpackChunkName: "pages/vendors/analytics/sale/fulfilled-gmv" */))
const _cfcfe6ec = () => interopDefault(import('../pages/vendors/analytics/sale/fulfilled-revenue.vue' /* webpackChunkName: "pages/vendors/analytics/sale/fulfilled-revenue" */))
const _7dec6c0c = () => interopDefault(import('../pages/vendors/analytics/sale/ordered-gmv.vue' /* webpackChunkName: "pages/vendors/analytics/sale/ordered-gmv" */))
const _084d27d8 = () => interopDefault(import('../pages/vendors/analytics/sale/ordered-revenue.vue' /* webpackChunkName: "pages/vendors/analytics/sale/ordered-revenue" */))
const _b67a42c8 = () => interopDefault(import('../pages/vendors/analytics/sale/sku-performance.vue' /* webpackChunkName: "pages/vendors/analytics/sale/sku-performance" */))
const _eda296ec = () => interopDefault(import('../pages/vendors/brands/index.vue' /* webpackChunkName: "pages/vendors/brands/index" */))
const _27687696 = () => interopDefault(import('../pages/vendors/claims/index.vue' /* webpackChunkName: "pages/vendors/claims/index" */))
const _7fd4fb9e = () => interopDefault(import('../pages/vendors/inventory.vue' /* webpackChunkName: "pages/vendors/inventory" */))
const _8b6c4018 = () => interopDefault(import('../pages/vendors/inventory/index.vue' /* webpackChunkName: "pages/vendors/inventory/index" */))
const _4b59e87a = () => interopDefault(import('../pages/vendors/inventory/all.vue' /* webpackChunkName: "pages/vendors/inventory/all" */))
const _599771ee = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/index.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/index" */))
const _c8a85a44 = () => interopDefault(import('../pages/vendors/inventory/inbound/index.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/index" */))
const _73d22d40 = () => interopDefault(import('../pages/vendors/inventory/status.vue' /* webpackChunkName: "pages/vendors/inventory/status" */))
const _cb105a08 = () => interopDefault(import('../pages/vendors/inventory/transfer/index.vue' /* webpackChunkName: "pages/vendors/inventory/transfer/index" */))
const _a5628afe = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/index.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/index" */))
const _010b1690 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/create.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/create" */))
const _0182d3a0 = () => interopDefault(import('../pages/vendors/inventory/inbound/create.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/create" */))
const _243ce05d = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/create.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/create" */))
const _7a9517d6 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/_id.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/_id" */))
const _f9a61674 = () => interopDefault(import('../pages/vendors/inventory/inbound/_id.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/_id" */))
const _92d33738 = () => interopDefault(import('../pages/vendors/inventory/transfer/_id.vue' /* webpackChunkName: "pages/vendors/inventory/transfer/_id" */))
const _92c92dae = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/_id.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/_id" */))
const _9a961d86 = () => interopDefault(import('../pages/vendors/notifications.vue' /* webpackChunkName: "pages/vendors/notifications" */))
const _a767b33a = () => interopDefault(import('../pages/vendors/orders/index.vue' /* webpackChunkName: "pages/vendors/orders/index" */))
const _8a1eb930 = () => interopDefault(import('../pages/vendors/payments.vue' /* webpackChunkName: "pages/vendors/payments" */))
const _2002082a = () => interopDefault(import('../pages/vendors/payments/index.vue' /* webpackChunkName: "pages/vendors/payments/index" */))
const _b0d60e34 = () => interopDefault(import('../pages/vendors/payments/payouts.vue' /* webpackChunkName: "pages/vendors/payments/payouts" */))
const _2651fade = () => interopDefault(import('../pages/vendors/payments/statements/index.vue' /* webpackChunkName: "pages/vendors/payments/statements/index" */))
const _3118a562 = () => interopDefault(import('../pages/vendors/products/index.vue' /* webpackChunkName: "pages/vendors/products/index" */))
const _7a811f24 = () => interopDefault(import('../pages/vendors/promotions/index.vue' /* webpackChunkName: "pages/vendors/promotions/index" */))
const _0bd6cda1 = () => interopDefault(import('../pages/vendors/samples/index.vue' /* webpackChunkName: "pages/vendors/samples/index" */))
const _9e17253e = () => interopDefault(import('../pages/vendors/settings/index.vue' /* webpackChunkName: "pages/vendors/settings/index" */))
const _53740af0 = () => interopDefault(import('../pages/vendors/stores/index.vue' /* webpackChunkName: "pages/vendors/stores/index" */))
const _02766144 = () => interopDefault(import('../pages/vendors/widgets.vue' /* webpackChunkName: "pages/vendors/widgets" */))
const _763dc6ae = () => interopDefault(import('../pages/vendors/claims/create.vue' /* webpackChunkName: "pages/vendors/claims/create" */))
const _19b0519c = () => interopDefault(import('../pages/vendors/products/create.vue' /* webpackChunkName: "pages/vendors/products/create" */))
const _863a2fe0 = () => interopDefault(import('../pages/vendors/promotions/create.vue' /* webpackChunkName: "pages/vendors/promotions/create" */))
const _40907c86 = () => interopDefault(import('../pages/vendors/promotions/draft.vue' /* webpackChunkName: "pages/vendors/promotions/draft" */))
const _68d170b7 = () => interopDefault(import('../pages/vendors/settings/general.vue' /* webpackChunkName: "pages/vendors/settings/general" */))
const _3b9f0212 = () => interopDefault(import('../pages/vendors/settings/invite-colleagues.vue' /* webpackChunkName: "pages/vendors/settings/invite-colleagues" */))
const _b7bb7034 = () => interopDefault(import('../pages/vendors/settings/minimums.vue' /* webpackChunkName: "pages/vendors/settings/minimums" */))
const _438cf777 = () => interopDefault(import('../pages/vendors/settings/notifications.vue' /* webpackChunkName: "pages/vendors/settings/notifications" */))
const _3ee820ee = () => interopDefault(import('../pages/vendors/settings/payments.vue' /* webpackChunkName: "pages/vendors/settings/payments" */))
const _787ed915 = () => interopDefault(import('../pages/vendors/settings/shopify.vue' /* webpackChunkName: "pages/vendors/settings/shopify" */))
const _90f9dc7a = () => interopDefault(import('../pages/vendors/orders/canceled/_id.vue' /* webpackChunkName: "pages/vendors/orders/canceled/_id" */))
const _4d145172 = () => interopDefault(import('../pages/vendors/brands/_id.vue' /* webpackChunkName: "pages/vendors/brands/_id" */))
const _45fd705d = () => interopDefault(import('../pages/vendors/claims/_id.vue' /* webpackChunkName: "pages/vendors/claims/_id" */))
const _073334ea = () => interopDefault(import('../pages/vendors/orders/_id.vue' /* webpackChunkName: "pages/vendors/orders/_id" */))
const _2bf4984a = () => interopDefault(import('../pages/vendors/products/_id.vue' /* webpackChunkName: "pages/vendors/products/_id" */))
const _767c3f4d = () => interopDefault(import('../pages/vendors/products/_id/index.vue' /* webpackChunkName: "pages/vendors/products/_id/index" */))
const _7d40bac3 = () => interopDefault(import('../pages/vendors/products/_id/request-change.vue' /* webpackChunkName: "pages/vendors/products/_id/request-change" */))
const _9d4ce8e4 = () => interopDefault(import('../pages/vendors/products/_id/skus/index.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/index" */))
const _bce79e20 = () => interopDefault(import('../pages/vendors/products/_id/skus/create.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/create" */))
const _de3e0c74 = () => interopDefault(import('../pages/vendors/products/_id/skus/_sid/index.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/_sid/index" */))
const _daaa9354 = () => interopDefault(import('../pages/vendors/promotions/_id.vue' /* webpackChunkName: "pages/vendors/promotions/_id" */))
const _8f64776e = () => interopDefault(import('../pages/vendors/samples/_id.vue' /* webpackChunkName: "pages/vendors/samples/_id" */))
const _841ce8ac = () => interopDefault(import('../pages/welcome-vendor.vue' /* webpackChunkName: "pages/welcome-vendor" */))
const _45c624e5 = () => interopDefault(import('../pages/brands/invite.vue' /* webpackChunkName: "pages/brands/invite" */))
const _ffa0f790 = () => interopDefault(import('../pages/capital/plus.vue' /* webpackChunkName: "pages/capital/plus" */))
const _50d63200 = () => interopDefault(import('../pages/capital/term-loans.vue' /* webpackChunkName: "pages/capital/term-loans" */))
const _58dbecd6 = () => interopDefault(import('../pages/pricing/earnings-calculator.vue' /* webpackChunkName: "pages/pricing/earnings-calculator" */))
const _05e26219 = () => interopDefault(import('../pages/services/brands/index.vue' /* webpackChunkName: "pages/services/brands/index" */))
const _049b27a9 = () => interopDefault(import('../pages/services/retailers.vue' /* webpackChunkName: "pages/services/retailers" */))
const _cc4e2f9c = () => interopDefault(import('../pages/services/brands/direct.vue' /* webpackChunkName: "pages/services/brands/direct" */))
const _102019f7 = () => interopDefault(import('../pages/services/brands/express.vue' /* webpackChunkName: "pages/services/brands/express" */))
const _5e58ef0b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _63809ae6 = () => interopDefault(import('../pages/invoices/buyers/orders/_id.vue' /* webpackChunkName: "pages/invoices/buyers/orders/_id" */))
const _219e1787 = () => interopDefault(import('../pages/invoices/buyers/samples/_id.vue' /* webpackChunkName: "pages/invoices/buyers/samples/_id" */))
const _7b4ca0ae = () => interopDefault(import('../pages/invoices/headBuyer/orders/_id.vue' /* webpackChunkName: "pages/invoices/headBuyer/orders/_id" */))
const _162eed86 = () => interopDefault(import('../pages/invoices/headBuyer/samples/_id.vue' /* webpackChunkName: "pages/invoices/headBuyer/samples/_id" */))
const _178f251a = () => interopDefault(import('../pages/invoices/lp/order/_id/index.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/index" */))
const _fa5fa094 = () => interopDefault(import('../pages/invoices/vendors/orders/_id.vue' /* webpackChunkName: "pages/invoices/vendors/orders/_id" */))
const _01c58104 = () => interopDefault(import('../pages/invoices/vendors/samples/_id.vue' /* webpackChunkName: "pages/invoices/vendors/samples/_id" */))
const _f95b77e4 = () => interopDefault(import('../pages/invoices/lp/order/_id/invoice.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/invoice" */))
const _a4bea8a2 = () => interopDefault(import('../pages/invoices/lp/order/_id/slip.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/slip" */))
const _090a7a18 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _057e7e0e = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _72860de6 = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _cf6c7d96 = () => interopDefault(import('~/pages/brand' /* webpackChunkName: "" */))
const _db92e1c6 = () => interopDefault(import('~/pages/product' /* webpackChunkName: "" */))
const _224ce406 = () => interopDefault(import('~/pages/setup-password' /* webpackChunkName: "" */))
const _16c8cba8 = () => interopDefault(import('~/pages/accept-invitation' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _3ea11446,
    name: "about"
  }, {
    path: "/accept-invitation",
    component: _60c7e037,
    name: "accept-invitation"
  }, {
    path: "/blog",
    component: _059d3788,
    name: "blog"
  }, {
    path: "/bloomberg-2024",
    component: _ecd9992e,
    name: "bloomberg-2024"
  }, {
    path: "/box-of-innovation",
    component: _ac75505c,
    name: "box-of-innovation"
  }, {
    path: "/brand",
    component: _5f8daf40,
    name: "brand"
  }, {
    path: "/brands",
    component: _607fabb4,
    name: "brands"
  }, {
    path: "/brokers",
    component: _3f9ef1b3,
    name: "brokers",
    children: [{
      path: "settings/general",
      component: _48b69088,
      name: "brokers-settings-general"
    }, {
      path: "settings/notifications",
      component: _165592f0,
      name: "brokers-settings-notifications"
    }]
  }, {
    path: "/buyers",
    component: _4b891f37,
    children: [{
      path: "",
      component: _1339207a,
      name: "buyers"
    }, {
      path: "analytics",
      component: _8bbe059e,
      name: "buyers-analytics"
    }, {
      path: "orders",
      component: _2636bfb0,
      name: "buyers-orders"
    }, {
      path: "payments",
      component: _ccaf1410,
      name: "buyers-payments"
    }, {
      path: "samples",
      component: _61814418,
      name: "buyers-samples"
    }, {
      path: "settings",
      component: _5a9de76e,
      name: "buyers-settings"
    }, {
      path: "payments/credit-memos",
      component: _cf7b0922,
      name: "buyers-payments-credit-memos"
    }, {
      path: "payments/statements",
      component: _15dda6b1,
      name: "buyers-payments-statements"
    }, {
      path: "settings/general",
      component: _4e049b84,
      name: "buyers-settings-general"
    }, {
      path: "settings/invite-colleagues",
      component: _37cb64f8,
      name: "buyers-settings-invite-colleagues"
    }, {
      path: "settings/notifications",
      component: _58961484,
      name: "buyers-settings-notifications"
    }, {
      path: "settings/payment",
      component: _845bf4bc,
      name: "buyers-settings-payment"
    }, {
      path: "orders/list/:type?",
      component: _63c0cc7a,
      name: "buyers-orders-list-type"
    }, {
      path: "orders/pre-order/:id?",
      component: _1a468f2d,
      name: "buyers-orders-pre-order-id"
    }, {
      path: "samples/list/:type?",
      component: _317478b6,
      name: "buyers-samples-list-type"
    }, {
      path: "orders/:id",
      component: _66d0ee18,
      name: "buyers-orders-id"
    }, {
      path: "samples/:id",
      component: _c9976548,
      name: "buyers-samples-id"
    }]
  }, {
    path: "/capital",
    component: _9f2ebbb8,
    name: "capital"
  }, {
    path: "/careers",
    component: _2dad3da4,
    name: "careers"
  }, {
    path: "/cart",
    component: _3eba38f7,
    name: "cart"
  }, {
    path: "/checkout",
    component: _d4905ec6,
    name: "checkout"
  }, {
    path: "/claim",
    component: _7c81a975,
    name: "claim"
  }, {
    path: "/claims",
    component: _175f264e,
    name: "claims"
  }, {
    path: "/favorites",
    component: _6155ad90,
    name: "favorites"
  }, {
    path: "/growth-deck",
    component: _d01e1830,
    name: "growth-deck"
  }, {
    path: "/head-buyer",
    component: _60a329c6,
    name: "head-buyer",
    children: [{
      path: "analytics",
      component: _de2485d2,
      name: "head-buyer-analytics"
    }, {
      path: "orders",
      component: _2d86798a,
      name: "head-buyer-orders"
    }, {
      path: "payments",
      component: _0bf12152,
      name: "head-buyer-payments"
    }, {
      path: "samples",
      component: _9c32414c,
      name: "head-buyer-samples"
    }, {
      path: "settings",
      component: _6632da70,
      name: "head-buyer-settings"
    }, {
      path: "analytics/avg-drop-size",
      component: _a5eae5ae,
      name: "head-buyer-analytics-avg-drop-size"
    }, {
      path: "analytics/avg-order-size",
      component: _24a7ebf4,
      name: "head-buyer-analytics-avg-order-size"
    }, {
      path: "analytics/best-selling-brands",
      component: _298eeb77,
      name: "head-buyer-analytics-best-selling-brands"
    }, {
      path: "analytics/fill-rate",
      component: _2517c6a2,
      name: "head-buyer-analytics-fill-rate"
    }, {
      path: "analytics/number-of-orders",
      component: _010e7c62,
      name: "head-buyer-analytics-number-of-orders"
    }, {
      path: "analytics/sale-velocity",
      component: _1a67b330,
      name: "head-buyer-analytics-sale-velocity"
    }, {
      path: "analytics/stock-report",
      component: _71531196,
      name: "head-buyer-analytics-stock-report"
    }, {
      path: "orders/create-multiple",
      component: _65d4860e,
      name: "head-buyer-orders-create-multiple"
    }, {
      path: "payments/credit-memos",
      component: _731d36d5,
      name: "head-buyer-payments-credit-memos"
    }, {
      path: "payments/statements",
      component: _44803f17,
      name: "head-buyer-payments-statements"
    }, {
      path: "settings/general",
      component: _50cfd85e,
      name: "head-buyer-settings-general"
    }, {
      path: "orders/pre-order/:id?",
      component: _1913cf07,
      name: "head-buyer-orders-pre-order-id"
    }, {
      path: "samples/list/:type?",
      component: _a33831e0,
      name: "head-buyer-samples-list-type"
    }, {
      path: "orders/:id",
      component: _3e995672,
      name: "head-buyer-orders-id"
    }, {
      path: "samples/:id",
      component: _870e1f7c,
      name: "head-buyer-samples-id"
    }]
  }, {
    path: "/help-center",
    component: _0255965a,
    children: [{
      path: "",
      component: _7beb1d46,
      name: "help-center"
    }, {
      path: "search",
      component: _2b0293ad,
      name: "help-center-search"
    }, {
      path: ":slug",
      component: _7f585fd6,
      name: "help-center-slug"
    }]
  }, {
    path: "/login",
    component: _28009222,
    name: "login"
  }, {
    path: "/lp",
    component: _604fbd1b,
    children: [{
      path: "",
      component: _4ae2c35e,
      name: "lp"
    }, {
      path: "inventory",
      component: _69b6c4e8,
      children: [{
        path: "",
        component: _1305fd6b,
        name: "lp-inventory"
      }, {
        path: "all",
        component: _0dbcfdfd,
        name: "lp-inventory-all"
      }, {
        path: "dispose-donate",
        component: _524bd797,
        name: "lp-inventory-dispose-donate"
      }, {
        path: "inbound",
        component: _24ca8955,
        name: "lp-inventory-inbound"
      }, {
        path: "withdrawal-requests",
        component: _7ef47678,
        name: "lp-inventory-withdrawal-requests"
      }, {
        path: "all/new",
        component: _419b9eea,
        name: "lp-inventory-all-new"
      }, {
        path: "all/:id",
        component: _690748b6,
        name: "lp-inventory-all-id"
      }, {
        path: "dispose-donate/:id?",
        component: _5148d3bf,
        name: "lp-inventory-dispose-donate-id"
      }, {
        path: "inbound/:id",
        component: _0aa4dcfd,
        name: "lp-inventory-inbound-id"
      }, {
        path: "withdrawal-requests/:id?",
        component: _80c49240,
        name: "lp-inventory-withdrawal-requests-id"
      }]
    }, {
      path: "orders",
      component: _9d425768,
      name: "lp-orders"
    }, {
      path: "pod-freight",
      component: _63219ac7,
      children: [{
        path: "",
        component: _78cab00a,
        name: "lp-pod-freight"
      }, {
        path: ":id",
        component: _02792cf2,
        name: "lp-pod-freight-id"
      }]
    }, {
      path: "settings",
      component: _87d975ec,
      name: "lp-settings"
    }, {
      path: "settings/general",
      component: _27990220,
      name: "lp-settings-general"
    }, {
      path: "orders/:id",
      component: _f2250c98,
      name: "lp-orders-id"
    }]
  }, {
    path: "/order-guide",
    component: _78653fb6,
    name: "order-guide"
  }, {
    path: "/ordered-variants",
    component: _053f7645,
    name: "ordered-variants"
  }, {
    path: "/pod-3pl",
    component: _b1f24d40,
    name: "pod-3pl"
  }, {
    path: "/pod-cost",
    component: _e25bb3a8,
    name: "pod-cost"
  }, {
    path: "/pod-freight",
    component: _1cbcde98,
    name: "pod-freight"
  }, {
    path: "/pod-insights",
    component: _65c27bba,
    name: "pod-insights"
  }, {
    path: "/pod-pax",
    component: _28f36098,
    name: "pod-pax"
  }, {
    path: "/podcasts",
    component: _5ee23ec9,
    name: "podcasts"
  }, {
    path: "/press",
    component: _584049fc,
    name: "press"
  }, {
    path: "/pricing",
    component: _3f72a302,
    name: "pricing"
  }, {
    path: "/privacy",
    component: _7455fa81,
    name: "privacy"
  }, {
    path: "/product",
    component: _7c5ccf30,
    name: "product"
  }, {
    path: "/products",
    component: _192323fe,
    name: "products"
  }, {
    path: "/promotions",
    component: _2aa1a2c7,
    name: "promotions"
  }, {
    path: "/referral",
    component: _8ae40918,
    name: "referral"
  }, {
    path: "/register",
    component: _490b3e7d,
    name: "register"
  }, {
    path: "/reset-password",
    component: _517fdff0,
    name: "reset-password"
  }, {
    path: "/retailers",
    component: _266022bc,
    name: "retailers"
  }, {
    path: "/setup-password",
    component: _0de31d82,
    name: "setup-password"
  }, {
    path: "/state-of-grocery",
    component: _148a4baa,
    name: "state-of-grocery"
  }, {
    path: "/team",
    component: _4a3bcc98,
    name: "team"
  }, {
    path: "/terms-of-service",
    component: _46c5b4c8,
    children: [{
      path: "",
      component: _6cf3f1c2,
      name: "terms-of-service"
    }, {
      path: "brands",
      component: _df6c7762,
      name: "terms-of-service-brands"
    }, {
      path: "logistics-partners",
      component: _1bdfcca8,
      name: "terms-of-service-logistics-partners"
    }, {
      path: "retailers",
      component: _f349de60,
      name: "terms-of-service-retailers"
    }]
  }, {
    path: "/terms-of-use",
    component: _79792aee,
    name: "terms-of-use"
  }, {
    path: "/vendors",
    component: _1f53d1a4,
    children: [{
      path: "",
      component: _02bd8d27,
      name: "vendors"
    }, {
      path: "analytics",
      component: _6a4a414a,
      children: [{
        path: "",
        component: _2e9b359e,
        name: "vendors-analytics"
      }, {
        path: "customer",
        component: _7674787c,
        children: [{
          path: "",
          component: _33261e76,
          name: "vendors-analytics-customer"
        }, {
          path: "churn",
          component: _d3116442,
          name: "vendors-analytics-customer-churn"
        }, {
          path: "cohort",
          component: _6bbc9912,
          name: "vendors-analytics-customer-cohort"
        }, {
          path: "first-time-and-returning",
          component: _09dfd954,
          name: "vendors-analytics-customer-first-time-and-returning"
        }, {
          path: "growth-accounting",
          component: _537ee54e,
          name: "vendors-analytics-customer-growth-accounting"
        }, {
          path: "one-time",
          component: _14384e31,
          name: "vendors-analytics-customer-one-time"
        }, {
          path: "over-time",
          component: _1cd07499,
          name: "vendors-analytics-customer-over-time"
        }, {
          path: "sales-by-region",
          component: _2e33205c,
          name: "vendors-analytics-customer-sales-by-region"
        }]
      }, {
        path: "inventory",
        component: _0927d1b0,
        children: [{
          path: "",
          component: _7cf6c0aa,
          name: "vendors-analytics-inventory"
        }, {
          path: "average-sold-per-week",
          component: _109faf26,
          name: "vendors-analytics-inventory-average-sold-per-week"
        }, {
          path: "current",
          component: _f4db86dc,
          name: "vendors-analytics-inventory-current"
        }, {
          path: "days-of-remaining",
          component: _6cab028f,
          name: "vendors-analytics-inventory-days-of-remaining"
        }, {
          path: "lost-sales",
          component: _5bf174cc,
          name: "vendors-analytics-inventory-lost-sales"
        }, {
          path: "month-end",
          component: _5db45f32,
          name: "vendors-analytics-inventory-month-end"
        }, {
          path: "percent-of-sold",
          component: _477fe9ca,
          name: "vendors-analytics-inventory-percent-of-sold"
        }, {
          path: "replenishment-lead-time",
          component: _2c56aeae,
          name: "vendors-analytics-inventory-replenishment-lead-time"
        }]
      }, {
        path: "lead-time",
        component: _6a429b6a,
        children: [{
          path: "",
          component: _dc792b26,
          name: "vendors-analytics-lead-time"
        }, {
          path: "pd-order-fulfillment",
          component: _34238f7b,
          name: "vendors-analytics-lead-time-pd-order-fulfillment"
        }, {
          path: "replenishment",
          component: _3a3f491f,
          name: "vendors-analytics-lead-time-replenishment"
        }]
      }, {
        path: "promotion",
        component: _fe100222,
        children: [{
          path: "",
          component: _dd81539c,
          name: "vendors-analytics-promotion"
        }, {
          path: "leading-to-repurchase",
          component: _8bbb09c2,
          name: "vendors-analytics-promotion-leading-to-repurchase"
        }, {
          path: "usage",
          component: _20f4a8fe,
          name: "vendors-analytics-promotion-usage"
        }]
      }, {
        path: "sale",
        component: _5d9dadab,
        children: [{
          path: "",
          component: _1903f024,
          name: "vendors-analytics-sale"
        }, {
          path: "avg-order-value",
          component: _1583ab53,
          name: "vendors-analytics-sale-avg-order-value"
        }, {
          path: "fulfilled-gmv",
          component: _898d0c84,
          name: "vendors-analytics-sale-fulfilled-gmv"
        }, {
          path: "fulfilled-revenue",
          component: _cfcfe6ec,
          name: "vendors-analytics-sale-fulfilled-revenue"
        }, {
          path: "ordered-gmv",
          component: _7dec6c0c,
          name: "vendors-analytics-sale-ordered-gmv"
        }, {
          path: "ordered-revenue",
          component: _084d27d8,
          name: "vendors-analytics-sale-ordered-revenue"
        }, {
          path: "sku-performance",
          component: _b67a42c8,
          name: "vendors-analytics-sale-sku-performance"
        }]
      }]
    }, {
      path: "brands",
      component: _eda296ec,
      name: "vendors-brands"
    }, {
      path: "claims",
      component: _27687696,
      name: "vendors-claims"
    }, {
      path: "inventory",
      component: _7fd4fb9e,
      children: [{
        path: "",
        component: _8b6c4018,
        name: "vendors-inventory"
      }, {
        path: "all",
        component: _4b59e87a,
        name: "vendors-inventory-all"
      }, {
        path: "dispose-donate",
        component: _599771ee,
        name: "vendors-inventory-dispose-donate"
      }, {
        path: "inbound",
        component: _c8a85a44,
        name: "vendors-inventory-inbound"
      }, {
        path: "status",
        component: _73d22d40,
        name: "vendors-inventory-status"
      }, {
        path: "transfer",
        component: _cb105a08,
        name: "vendors-inventory-transfer"
      }, {
        path: "withdrawal-requests",
        component: _a5628afe,
        name: "vendors-inventory-withdrawal-requests"
      }, {
        path: "dispose-donate/create",
        component: _010b1690,
        name: "vendors-inventory-dispose-donate-create"
      }, {
        path: "inbound/create",
        component: _0182d3a0,
        name: "vendors-inventory-inbound-create"
      }, {
        path: "withdrawal-requests/create",
        component: _243ce05d,
        name: "vendors-inventory-withdrawal-requests-create"
      }, {
        path: "dispose-donate/:id?",
        component: _7a9517d6,
        name: "vendors-inventory-dispose-donate-id"
      }, {
        path: "inbound/:id",
        component: _f9a61674,
        name: "vendors-inventory-inbound-id"
      }, {
        path: "transfer/:id",
        component: _92d33738,
        name: "vendors-inventory-transfer-id"
      }, {
        path: "withdrawal-requests/:id?",
        component: _92c92dae,
        name: "vendors-inventory-withdrawal-requests-id"
      }]
    }, {
      path: "notifications",
      component: _9a961d86,
      name: "vendors-notifications"
    }, {
      path: "orders",
      component: _a767b33a,
      name: "vendors-orders"
    }, {
      path: "payments",
      component: _8a1eb930,
      children: [{
        path: "",
        component: _2002082a,
        name: "vendors-payments"
      }, {
        path: "payouts",
        component: _b0d60e34,
        name: "vendors-payments-payouts"
      }, {
        path: "statements",
        component: _2651fade,
        name: "vendors-payments-statements"
      }]
    }, {
      path: "products",
      component: _3118a562,
      name: "vendors-products"
    }, {
      path: "promotions",
      component: _7a811f24,
      name: "vendors-promotions"
    }, {
      path: "samples",
      component: _0bd6cda1,
      name: "vendors-samples"
    }, {
      path: "settings",
      component: _9e17253e,
      name: "vendors-settings"
    }, {
      path: "stores",
      component: _53740af0,
      name: "vendors-stores"
    }, {
      path: "widgets",
      component: _02766144,
      name: "vendors-widgets"
    }, {
      path: "claims/create",
      component: _763dc6ae,
      name: "vendors-claims-create"
    }, {
      path: "products/create",
      component: _19b0519c,
      name: "vendors-products-create"
    }, {
      path: "promotions/create",
      component: _863a2fe0,
      name: "vendors-promotions-create"
    }, {
      path: "promotions/draft",
      component: _40907c86,
      name: "vendors-promotions-draft"
    }, {
      path: "settings/general",
      component: _68d170b7,
      name: "vendors-settings-general"
    }, {
      path: "settings/invite-colleagues",
      component: _3b9f0212,
      name: "vendors-settings-invite-colleagues"
    }, {
      path: "settings/minimums",
      component: _b7bb7034,
      name: "vendors-settings-minimums"
    }, {
      path: "settings/notifications",
      component: _438cf777,
      name: "vendors-settings-notifications"
    }, {
      path: "settings/payments",
      component: _3ee820ee,
      name: "vendors-settings-payments"
    }, {
      path: "settings/shopify",
      component: _787ed915,
      name: "vendors-settings-shopify"
    }, {
      path: "orders/canceled/:id?",
      component: _90f9dc7a,
      name: "vendors-orders-canceled-id"
    }, {
      path: "brands/:id",
      component: _4d145172,
      name: "vendors-brands-id"
    }, {
      path: "claims/:id",
      component: _45fd705d,
      name: "vendors-claims-id"
    }, {
      path: "orders/:id",
      component: _073334ea,
      name: "vendors-orders-id"
    }, {
      path: "products/:id",
      component: _2bf4984a,
      children: [{
        path: "",
        component: _767c3f4d,
        name: "vendors-products-id"
      }, {
        path: "request-change",
        component: _7d40bac3,
        name: "vendors-products-id-request-change"
      }, {
        path: "skus",
        component: _9d4ce8e4,
        name: "vendors-products-id-skus"
      }, {
        path: "skus/create",
        component: _bce79e20,
        name: "vendors-products-id-skus-create"
      }, {
        path: "skus/:sid",
        component: _de3e0c74,
        name: "vendors-products-id-skus-sid"
      }]
    }, {
      path: "promotions/:id",
      component: _daaa9354,
      name: "vendors-promotions-id"
    }, {
      path: "samples/:id",
      component: _8f64776e,
      name: "vendors-samples-id"
    }]
  }, {
    path: "/welcome-vendor",
    component: _841ce8ac,
    name: "welcome-vendor"
  }, {
    path: "/brands/invite",
    component: _45c624e5,
    name: "brands-invite"
  }, {
    path: "/capital/plus",
    component: _ffa0f790,
    name: "capital-plus"
  }, {
    path: "/capital/term-loans",
    component: _50d63200,
    name: "capital-term-loans"
  }, {
    path: "/pricing/earnings-calculator",
    component: _58dbecd6,
    name: "pricing-earnings-calculator"
  }, {
    path: "/services/brands",
    component: _05e26219,
    name: "services-brands"
  }, {
    path: "/services/retailers",
    component: _049b27a9,
    name: "services-retailers"
  }, {
    path: "/services/brands/direct",
    component: _cc4e2f9c,
    name: "services-brands-direct"
  }, {
    path: "/services/brands/express",
    component: _102019f7,
    name: "services-brands-express"
  }, {
    path: "/",
    component: _5e58ef0b,
    name: "index"
  }, {
    path: "/invoices/buyers/orders/:id?",
    component: _63809ae6,
    name: "invoices-buyers-orders-id"
  }, {
    path: "/invoices/buyers/samples/:id?",
    component: _219e1787,
    name: "invoices-buyers-samples-id"
  }, {
    path: "/invoices/headBuyer/orders/:id?",
    component: _7b4ca0ae,
    name: "invoices-headBuyer-orders-id"
  }, {
    path: "/invoices/headBuyer/samples/:id?",
    component: _162eed86,
    name: "invoices-headBuyer-samples-id"
  }, {
    path: "/invoices/lp/order/:id",
    component: _178f251a,
    name: "invoices-lp-order-id"
  }, {
    path: "/invoices/vendors/orders/:id?",
    component: _fa5fa094,
    name: "invoices-vendors-orders-id"
  }, {
    path: "/invoices/vendors/samples/:id?",
    component: _01c58104,
    name: "invoices-vendors-samples-id"
  }, {
    path: "/invoices/lp/order/:id?/invoice",
    component: _f95b77e4,
    name: "invoices-lp-order-id-invoice"
  }, {
    path: "/invoices/lp/order/:id?/slip",
    component: _a4bea8a2,
    name: "invoices-lp-order-id-slip"
  }, {
    path: "/blog/:slug",
    component: _090a7a18,
    name: "blog-slug"
  }, {
    path: "/brands/:id",
    component: _057e7e0e,
    name: "brands-id"
  }, {
    path: "/products/:id",
    component: _72860de6,
    name: "products-id"
  }, {
    path: "/:slug-b:id(\\d+).html",
    component: _cf6c7d96,
    name: "_brand"
  }, {
    path: "/:slug-p:id(\\d+).html",
    component: _db92e1c6,
    name: "_product"
  }, {
    path: "/setup-password/:user_role",
    component: _224ce406,
    name: "_setup_password"
  }, {
    path: "/vendor/vendor_auth/invitation/accept",
    component: _16c8cba8,
    name: "_vendor_accept_invitation"
  }, {
    path: "/buyer/buyer_auth/invitation/accept",
    component: _16c8cba8,
    name: "_buyer_accept_invitation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
